<script setup></script>
<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 30 29"
        fill="none"
    >
        <path
            opacity="0.32"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.6679 9.94092C23.9282 10.3785 21.8741 10.4076 20.1 9.86558C19.3499 9.63642 18.7651 9.05213 18.512 8.30985C17.8813 6.4607 17.7256 4.19731 18.189 2.45511C17.2295 2.43101 16.1382 2.41669 14.8999 2.41669C10.6898 2.41669 8.1809 2.58202 6.75918 2.73543C5.70589 2.84909 4.89371 3.56251 4.6965 4.60337C4.38952 6.2236 4.0249 9.25253 4.0249 14.5C4.0249 19.7475 4.38952 22.7764 4.6965 24.3967C4.89371 25.4376 5.70589 26.151 6.75918 26.2646C8.1809 26.418 10.6898 26.5834 14.8999 26.5834C19.11 26.5834 21.6189 26.418 23.0406 26.2646C24.0939 26.151 24.9061 25.4376 25.1033 24.3967C25.4103 22.7764 25.7749 19.7475 25.7749 14.5C25.7749 12.7413 25.7339 11.2317 25.6679 9.94092ZM10.0666 15.7084C9.39921 15.7084 8.85824 15.1674 8.85824 14.5C8.85824 13.8327 9.39921 13.2917 10.0666 13.2917H14.8999C15.5673 13.2917 16.1082 13.8327 16.1082 14.5C16.1082 15.1674 15.5673 15.7084 14.8999 15.7084H10.0666ZM10.0666 21.1459C9.39921 21.1459 8.85824 20.6049 8.85824 19.9375C8.85824 19.2702 9.39921 18.7292 10.0666 18.7292H18.5249C19.1923 18.7292 19.7332 19.2702 19.7332 19.9375C19.7332 20.6049 19.1923 21.1459 18.5249 21.1459H10.0666Z"
            class="fill-[#637381] group-[&.active]:fill-main"
        />
        <path
            d="M8.8584 19.9375C8.8584 20.6049 9.39937 21.1458 10.0667 21.1458H18.5251C19.1924 21.1458 19.7334 20.6049 19.7334 19.9375C19.7334 19.2701 19.1924 18.7292 18.5251 18.7292H10.0667C9.39937 18.7292 8.8584 19.2701 8.8584 19.9375Z"
            class="fill-[#637381] group-[&.active]:fill-main"
        />
        <path
            d="M8.8584 14.5C8.8584 15.1674 9.39937 15.7083 10.0667 15.7083H14.9001C15.5674 15.7083 16.1084 15.1674 16.1084 14.5C16.1084 13.8326 15.5674 13.2917 14.9001 13.2917H10.0667C9.39937 13.2917 8.8584 13.8326 8.8584 14.5Z"
            class="fill-[#637381] group-[&.active]:fill-main"
        />
        <path
            d="M25.6681 9.9409C23.9284 10.3785 21.8743 10.4076 20.1002 9.86556C19.3501 9.6364 18.7653 9.05211 18.5121 8.30983C17.8815 6.46065 17.7257 4.19723 18.1892 2.45502C18.1892 2.45502 19.6683 3.02076 22.3871 5.73951C25.1058 8.45827 25.6681 9.9409 25.6681 9.9409Z"
            class="fill-[#637381] group-[&.active]:fill-main"
        />
    </svg>
</template>
